// eslint-disable-next-line func-style
export function urnToId(urn) {
  if (urn) {
    const urnParts = urn.split(':');
    return urnParts[urnParts.length - 1];
  }
}
export const urnToTopicMediaType = urn => {
  const [,,, mediaType] = urn.split(':');
  return mediaType;
};
export const idToUrn = (id, businessUnit, urnType) => `urn:${businessUnit}:${urnType}:${id}`;
export const isAudio = urn => urn.includes('radio');
export const isShow = urn => urn.includes('show:tv');
export const isUrn = value => {
  const numberOfColons = value.split(':').length;
  return (value.startsWith('urn:') && !value.includes(' ') && numberOfColons >= 4) ?? false;
};
export const mapUrnToMediaType = urn => isAudio(urn) ? 'AUDIO' : 'VIDEO';
export const getBusinessUnit = urn => {
  const businessUnit = urn.split(':')[1];
  return businessUnit;
};